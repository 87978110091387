import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { CatalogModel } from '../models/catalog.model';
import { CountryModel } from '../models/country.model';


@Injectable({
  providedIn: 'root'
})
export class CatalogService {

  private url = "https://develop.api.tbs.galapagosislands.com/";
  constructor(
    private http: HttpClient
  ) { }

  getCatalog(tkn:string, type:string, param:string): Observable<CatalogModel[]>{
    const httpOptions = {
      headers: this.headers(tkn)
    };
    const url = this.url+'catalogo/getByTipo/'+type+'/'+param;
    return this.http.get<CatalogModel[]>(url, httpOptions);
  }

  getCountries(tkn:string):Observable<CountryModel[]>{
    const httpOptions = {
      headers: this.headers(tkn)
    };
    const url = this.url+'pais';
    return this.http.get<CountryModel[]>(url, httpOptions);
  }

  getCountryCities(tkn:string, countryCode:string):Observable<CountryModel[]>{
    const httpOptions = {
      headers: this.headers(tkn)
    };
    const url = this.url+'pais/getCiudades/'+countryCode;
    return this.http.get<CountryModel[]>(url, httpOptions);
  }

  headers(tkn: any): HttpHeaders {
    return new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${tkn}`
    });
  }

}
