import { Injectable, OnDestroy } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ListActiveUsersService implements OnDestroy {

  listener:any;


  constructor() { 
    console.log("ActiveUsersInit");
    window.addEventListener("storage", this.storageEventListener.bind(this));
  }

  private storageEventListener(event: StorageEvent) {
    if (event.storageArea == localStorage) {
      if (event?.key && event.key == 'update-active-users') {
        if(event.newValue=='1'){
          window.location.reload();
          localStorage.setItem('update-active-users','0');
        }
      }
    }
  }

  ngOnDestroy(): void {
    
  }
}
