import { Injectable } from "@angular/core";
import { CompanieEnum } from "../enums/companie.enum";

@Injectable({
    providedIn: 'root'
})
export class GeneralUtils {
    isNullOrUndefined(param: any): boolean {
        return (param === null || param === undefined);
    }

    clearLocalStorage(ccode: any, ucode: any) {
        localStorage.removeItem('userdata_' + ccode + '_' + ucode);
        localStorage.removeItem('Sidebar_' + ccode + '_' + ucode);
        localStorage.removeItem('GradientColor_' + ccode + '_' + ucode);
        localStorage.removeItem('tkn_' + ccode + '_' + ucode);
        localStorage.removeItem('modal-header-color_' + ccode + '_' + ucode);
        localStorage.removeItem('categoryList_' + ccode + '_' + ucode);
        localStorage.removeItem('companyList_' + ccode + '_' + ucode);
        localStorage.removeItem('bookingStatusList_' + ccode + '_' + ucode);
        localStorage.removeItem('roleList_' + ccode + '_' + ucode);
        localStorage.removeItem('saleTypeList_' + ccode + '_' + ucode);
        localStorage.removeItem('locationList_' + ccode + '_' + ucode);
        localStorage.removeItem('hotelLocationList_' + ccode + '_' + ucode);
        localStorage.removeItem('tourDaysList_' + ccode + '_' + ucode);
        localStorage.removeItem('cruiseDaysList_' + ccode + '_' + ucode);
        localStorage.removeItem('shipList_' + ccode + '_' + ucode);
        localStorage.removeItem('hotelNightsList_' + ccode + '_' + ucode);
        localStorage.removeItem('countriesList_' + ccode + '_' + ucode);
        localStorage.removeItem('modal-header-color_' + ccode + '_' + ucode);
        localStorage.removeItem('sourceList_' + ccode + '_' + ucode);
    }

    formatDateForService(date: Date): string {
        return (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()).toString() + (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1).toString() + date.getFullYear().toString();
    }

    setCompanycolor(companyCode: number): string {
        let companyClass = "";
        switch (companyCode) {
            case CompanieEnum.gtcCode:
                companyClass = "theme-orange";
                break;

            case CompanieEnum.royalCode:
                companyClass = "theme-blue";
                break;

            default:
                companyClass = "theme-green";
                break;

        }
        return companyClass;
    }

}