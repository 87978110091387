import { Injectable, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class SessionService implements OnDestroy {

  isLoggedIn: boolean = false

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {
    this.start()
  }

  public login = () => {
    this.isLoggedIn = true;
    //this.router.navigate([''])
  }

  public logOut = (ccode: any, ucode: any) => {
    this.isLoggedIn = false;
    sessionStorage.clear();
    this.router.navigate(['/login'])
  }

  // Bind the eventListener
  private start(): void {
    console.log('start');
    window.addEventListener("storage", this.storageEventListener.bind(this));
  }

  // Logout only when key is 'logout-event'
  private storageEventListener(event: StorageEvent) {
    let ccode = localStorage.getItem('ccode');
    let ucode = localStorage.getItem('ucode');
    let urccode = this.activatedRoute.snapshot.params['ccode'];
    let urucode = this.activatedRoute.snapshot.params['ucode'];

    if (event.storageArea == localStorage) {
      if (event?.key && event.key == 'logout-event_' + ccode + '_' + ucode) {
        if (ccode == urccode && ucode == urucode) {
          this.logOut(ccode, ucode);
        }
      }
    }
  }

  // Handle active listeners when onDestroy 
  private stop(): void {
    window.removeEventListener("storage", this.storageEventListener.bind(this));
  }

  ngOnDestroy() {
    this.stop();
  }

}