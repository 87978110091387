import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'Galavail - S.B.T';
  greenClass: boolean = true;
  orageClass: boolean = false;
  blushClass: boolean = false;
  cyanClass: boolean = false;
  timberClass: boolean = false;
  blueClass: boolean = false;
  amethystClass: boolean = false;
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private titleService: Title
  ) {}
  ngOnInit(): void {
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe(() => {
        var rt = this.getChild(this.activatedRoute);

        rt.data.subscribe((data: any) => {
          this.titleService.setTitle(data.title);
        });
      });

    setTimeout(() => {
      if (typeof document !== 'undefined') {
        document
          .getElementsByClassName('page-loader-wrapper')[0]
          .classList.add('HideDiv');
      }
    }, 1000);
  }

  getChild(activatedRoute: ActivatedRoute): any {
    if (activatedRoute.firstChild) {
      return this.getChild(activatedRoute.firstChild);
    } else {
      return activatedRoute;
    }
  }
  toggleThemeSetting() {
    if (typeof document !== 'undefined') {
      const className = document.getElementsByClassName('themesetting')[0];
      className.classList.toggle('open');
    }
  }
  ToggleLight(e: any) {
    if (typeof document !== 'undefined') {
      const className = document.getElementById('left-sidebar');
      if (e.target.checked) {
        try {
          sessionStorage.setItem('Sidebar', 'light_active');
        } catch (error) {}
        className!.classList.add('light_active');
      } else {
        try {
          sessionStorage.setItem('Sidebar', '');
        } catch (error) {}
        className!.classList.remove('light_active');
      }
    }
  }
  ToggleGradient(e: any) {
    if (typeof document !== 'undefined') {
      const className = document.getElementsByClassName('theme-bg');

      for (let index = 0; index < className.length; index++) {
        const element = className[index];
        if (e.target.checked) {
          element.classList.add('gradient');
          try {
            sessionStorage.setItem('GradientColor', 'gradient');
          } catch (error) {}
        } else {
          element.classList.remove('gradient');
          try {
            sessionStorage.setItem('GradientColor', '');
          } catch (error) {}
        }
      }
    }
  }
  ToggleDarkMode(e: any) {
    if (typeof document !== 'undefined') {
      const className = document.getElementById('MooliHtml');
      if (e.target.checked) {
        className!.setAttribute('data-theme', 'dark');
      } else {
        className!.setAttribute('data-theme', 'light');
      }
    }
  }
  ToggleRTL(e: any) {
    if (typeof document !== 'undefined') {
      const body = document.getElementsByTagName('body')[0];
      if (e.target.checked) {
        body.classList.add('rtl_active');
      } else {
        body.classList.remove('rtl_active');
      }
    }
  }

  themeColor(color: any) {
    this.greenClass = false;
    this.orageClass = false;
    this.blushClass = false;
    this.cyanClass = false;
    this.timberClass = false;
    this.blueClass = false;
    this.amethystClass = false;
    if (typeof document !== 'undefined') {
      const className = document.getElementById('body');
      className!.removeAttribute('class');
      if (color == 'g') {
        this.greenClass = true;
        className!.classList.add('theme-green');
      } else if (color == 'o') {
        this.orageClass = true;
        className!.classList.add('theme-orange');
      } else if (color == 'b') {
        this.blushClass = true;
        className!.classList.add('theme-blush');
      } else if (color == 'c') {
        this.cyanClass = true;
        className!.classList.add('theme-cyan');
      } else if (color == 't') {
        this.timberClass = true;
        className!.classList.add('theme-timber');
      } else if (color == 'bl') {
        this.blueClass = true;
        className!.classList.add('theme-blue');
      } else if (color == 'a') {
        this.amethystClass = true;
        className!.classList.add('theme-amethyst');
      }
    }
   
  }

  closeMenu() {
    if (typeof document !== 'undefined') {
      document.getElementById('rightbar')!.classList.remove('open');
      document
        .getElementsByClassName('sticky-note')[0]
        .classList.remove('open');
      document.getElementsByClassName('overlay')[0].classList.remove('open');
      document
        .getElementsByTagName('body')[0]
        .classList.remove('offcanvas-active');
    }
  }
}
